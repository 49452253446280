import React, {useContext} from "react";
import { useNavigate } from 'react-router-dom';
import '../styles/navBarStyles.css'; // Import the CSS file

import logoImage from '../images/download.jpg';
import VariableContext from '../context/variableContext';
import profile from '../images/bg2.png';
const Navbar = ({ pageTitle, logout }) => {
  //const { setVariables,dataGroup,setdataGroup,projectId,setProjectId,retrieveBannersFromBackend,banners,fetchWeights,savedWeightings } = useContext(VariableContext); // Use setVariables from context
  const { setVariables,dataGroup,setdataGroup,projectId,setProjectId,setSavedWeightings,setWeightingStats,setBanners } = useContext(VariableContext); // Use setVariables from context
  const navigate = useNavigate();

  const handleSignOut = () => {
    setBanners([]);
    setWeightingStats({});
    setProjectId(null);
    setSavedWeightings({});
    logout(); // Call logout from the context
    navigate('/login');
  };

  return (
    <div className="navbar">
      <img src={logoImage} alt="Logo" className="navbar-logo" />
      <h1 className="navbar-title">{pageTitle}</h1>
      <div className="navbar-right">
        <img src={profile} alt="Profile" className="navbar-profile-pic" />
        <button onClick={handleSignOut} className="navbar-signout-btn">Sign Out</button>
      </div>
    </div>
  );
};

export default Navbar;
