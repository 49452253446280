import React, { useState } from 'react';
import axios from 'axios';
import '../styles/pollStudio.css';

function FrontEnd() {
  const [questions, setQuestions] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (event) => {
    debugger;
    try {
        debugger;
      const res = await axios.post('http://127.0.0.1:5000/process', { questions });
      setResponse(res.data);
    } catch (error) {
      console.error('Error sending data to server:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Questionnaire Processor</h1>
        <form onSubmit={handleSubmit}>
          <textarea
            value={questions}
            onChange={(e) => setQuestions(e.target.value)}
            placeholder="Paste your questions here"
            rows="10"
            cols="50"
          />
          <br />
          <button type="submit">Submit Questions</button>
        </form>
        {response && <pre>Response: {JSON.stringify(response, null, 2)}</pre>}
      </header>
    </div>
  );
}

export default FrontEnd;
