// TableSettings.js
import React, { useState, useContext } from 'react';
import VariableContext from '../context/variableContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/TableSettings.css'; // Make sure the path matches your file structure
import {  faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import PropertiesComponent from './PropertiesComponent'; // Import the Modal component

import { useNavigate  } from 'react-router-dom'; // Import useHistory
const TableSettings = () => {
    const { dataGroup, setdataGroup,projectId } = useContext(VariableContext);
    const [selectedVariable, setSelectedVariable] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate hook
    const defaultNewProperties = {
        removeTab: false,
        mean: false,
        median: false,
        grid: false,
        reverseGrid: false,
        addNets: false,
        saveToExcel: false,
        downloadDVExcel: false,
        SPSSSyntax: false,
        conceptTables: false,
        reverseLocation: false,
        dataCuts: false,
        rank: false,
        rankalphabetical: false,
        rankCol:false
    };
 

const handleVariableSelect = (variable) => {
    // const mergedProperties = {};
    // for (const key in defaultNewProperties) {
    //     if (!(key in dataGroup[variable])) {
    //         mergedProperties[key] = defaultNewProperties[key];
    //     }
    // }
    setSelectedVariable(dataGroup[variable]);
    setSelectedKey(variable);
};

    const goToUploadData = () => {
        navigate('/uploaddata'); // Replace '/upload-data' with the actual path to your Upload data page
    };

    return (
    <div className="table-settings">
        <div className="variable-list">
        <button onClick={goToUploadData}>
                <FontAwesomeIcon icon={faArrowLeft} /> Back to Upload Data
            </button>

            <h2>Variables</h2>
            <ul>
                {dataGroup && Object.keys(dataGroup).map((variable) => (
                    <li
                        key={variable}
                        onClick={() => handleVariableSelect(variable)}
                        className={selectedVariable?.name === variable ? 'selected' : ''}
                    >
                        {variable}
                    </li>
                ))}
            </ul>
        </div>
        
        <PropertiesComponent
        selectedVariable={selectedVariable}
        setdataGroup={setdataGroup}
        dataGroup={dataGroup}
        selectedKey={selectedKey}
        setSelectedVariable={setSelectedVariable}
        projectId={projectId}
        ></PropertiesComponent>
    </div>
);
};

export default TableSettings;