import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import VariableContext from '../context/variableContext';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import '../styles/variableLogicBuilderStyles.css'; // Ensure you have this CSS for styling
import config from '../config'; // Adjust the import path as necessary

const VariableLogicBuilder = () => {
  const navigate = useNavigate();
  const { dataGroup,setDataGroup, projectId } = useContext(VariableContext);
  
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [recoding, setRecoding] = useState(''); // New state for recoding input
   
  
  const [selectedKey, setSelectedKey] = useState([]);
  const [newVariableName, setNewVariableName] = useState('');
  const [punch, setPunch] = useState('');
  const [newVariable, setNewVariable] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVariableChange = (event) => {
    setSelectedVariables([...event.target.selectedOptions].map(o => dataGroup[o.value]));
    setSelectedKey([...event.target.selectedOptions].map(o =>o.value));
  };

  const createVariableBasedOnType = async () => {
    try {
      const endpoint = selectedVariables.length === 1 && ['SingleResponse', 'Numeric'].includes(dataGroup[selectedKey[0]].type)
        ? `${config.API_BASE_URL}/create-recoded-variable`
        : `${config.API_BASE_URL}/create-combined-variable`;
      
      const response = await axios.post(endpoint, {
        projectId,
        variables: selectedVariables,
        punch: punch,
        recoding: recoding, // Include recoding rules
        newVariableName,
        operation: "OR" // Hardcoded for simplicity, could be dynamic
      });

      setNewVariable(response.data);
      setErrorMessage('');
      dataGroup[response.data['new_variable_name']]=response.data['newDatagroup']
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to create variable.");
    }
  };
  const createCombinedVariable = async () => {
    if (selectedVariables.length < 2) {
      setErrorMessage("Please select at least two variables to combine.");
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}/create-combined-variable`, {
        projectId,
        variables: selectedVariables,
        punch:punch,
        newVariableName,
        operation: "OR"  // This is hardcoded for now, can be dynamic if needed
      });
      setNewVariable(response.data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to create combined variable.");
    }
  };
  
  const create3DGridVariable = async () => {
    if (selectedVariables.length > 1 || selectedVariables.length < 1 ) {
      setErrorMessage("Please select at least two variables to combine.");
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}/create-3dGrid-variable`, {
        projectId,
        variables: selectedVariables,
        newVariableName,
        operation: "OR"  // This is hardcoded for now, can be dynamic if needed
      });
      setNewVariable(response.data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to create combined variable.");
    }
  };
  const createmultiVariable = async () => {
    if (selectedVariables.length > 1 || selectedVariables.length < 1 ) {
      setErrorMessage("Please select at least two variables to combine.");
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}/create-multi-variable`, {
        projectId,
        variables: selectedVariables,
        newVariableName,
        operation: "OR"  // This is hardcoded for now, can be dynamic if needed
      });
      setNewVariable(response.data);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to create combined variable.");
    }
  };
  return (
    <div className="variable-logic-builder-container">
      <div className="sidebar">
        <button onClick={() => navigate('/uploaddata')}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Upload Data
        </button>
        <h2>Variables</h2>
        <select multiple value={selectedKey} onChange={handleVariableChange} className="variable-select">
          {Object.keys(dataGroup).map(variable => (
            <option key={variable} value={variable}>{variable}</option>
          ))}
        </select>
      </div>
      <div className="main-content">
        <input
          type="text"
          value={newVariableName}
          onChange={e => setNewVariableName(e.target.value)}
          placeholder="New Variable Name"
        />
        <input
          type="text"
          value={punch}
          onChange={e => setPunch(e.target.value)}
          placeholder="Enter value to be taken"
        />
{selectedKey.length === 1 && ['SingleResponse', 'Numeric'].includes(dataGroup[selectedKey[0]].type) && (
          <input
            type="text"
            value={recoding}
            onChange={e => setRecoding(e.target.value)}
            placeholder="Enter recoding brackets (e.g., 1,2:1; 3,4:2)"
          />
        )}
        <button onClick={createVariableBasedOnType}>Create Variable</button>
          {selectedKey && (selectedKey.length>1) && (<button onClick={createCombinedVariable}>Create Combined Variable</button>)}
        {selectedKey && selectedKey.length==1 && dataGroup[selectedKey[0]].type!='Multiple3DGrid' && (<button onClick={createmultiVariable}>Combine Grid to Multi Response</button>)}
        {selectedKey && (selectedKey.length==1 && dataGroup[selectedKey[0]].type=='Multiple3DGrid') && (<button onClick={create3DGridVariable}>Create Combined MultiGrid Variable</button>)}
        {/* {newVariable && <div>New Variable: {JSON.stringify(newVariable)}</div>}
        {errorMessage && <div>Error: {errorMessage}</div>} */}
{JSON.stringify(dataGroup)}
      </div>
    </div>
  );
};

export default VariableLogicBuilder;
