import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './Providers/AuthContext';
import Navbar from "./NavBars/Navbar"; // Import Navbar component
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import HomePage from './components/HomePage';
import UploadData from './components/UploadData';
import DragDropPage from './components/sample';
import Dashboard from './components/Dashboard';
import WeightingPage from './components/WeightingPage';
import VariableLogicBuilder from './components/VariableLogicBuilder';
import TableSettings from './components/TableSettings';
import FronEnd from './components/pollStudioFront';
import VariableProvider from './Providers/VariableProvider';
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react"; // Import MsalProvider and useIsAuthenticated
import { msalInstance } from "./msalConfig"; // Import your MSAL configuration
import ManageCrossBreaks from './components/ManageCrossBreaks';

// PrivateRoute component to protect routes that require authentication
const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" />;
};

const AppRoutes = () => {
  const [pageTitle, setPageTitle] = useState("Home"); // State for page title
  const location = useLocation();
  const shouldShowNavbar = location.pathname !== "/login";
  const { logout } = useAuth();

  return (
    <VariableProvider>
      <>
        {shouldShowNavbar && <Navbar pageTitle={pageTitle} logout={logout} />}
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/home" element={<PrivateRoute element={HomePage} />} />
          <Route path="/uploaddata" element={<PrivateRoute element={UploadData} />} />
          <Route path="/drag" element={<PrivateRoute element={DragDropPage} />} />
          <Route path="/fronend" element={<PrivateRoute element={FronEnd} />} />
          <Route path="/weighting" element={<PrivateRoute element={WeightingPage} />} />
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/variablelogicbuilder" element={<PrivateRoute element={VariableLogicBuilder} />} />
          <Route path="/manage-crossbreaks" element={<PrivateRoute element={ManageCrossBreaks} />} />
          <Route path="/tablesettings" element={<PrivateRoute element={TableSettings} />} />
        </Routes>
      </>
    </VariableProvider>
  );
};

function App() {
  return (
    <MsalProvider instance={msalInstance}> {/* Wrap the app with MsalProvider */}
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
