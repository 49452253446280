import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const initialItems = {
  list1: ['Item 1', 'Item 2', 'Item 3'],
  list2: ['Item 4', 'Item 5', 'Item 6'],
};

const DragDropPage = () => {
  const [items, setItems] = useState(initialItems);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const itemsCopy = [...items[source.droppableId]];
      const [reorderedItem] = itemsCopy.splice(source.index, 1);
      itemsCopy.splice(destination.index, 0, reorderedItem);

      setItems({
        ...items,
        [source.droppableId]: itemsCopy,
      });
    } else {
      const sourceItems = [...items[source.droppableId]];
      const destinationItems = [...items[destination.droppableId]];
      const [movedItem] = sourceItems.splice(source.index, 1);

      destinationItems.splice(destination.index, 0, movedItem);

      setItems({
        ...items,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destinationItems,
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Object.entries(items).map(([listId, listItems]) => (
        <Droppable droppableId={listId} key={listId}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <h4>{listId}</h4>
              {listItems.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: 16,
                        margin: '0 0 8px 0',
                        backgroundColor: '#fff',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
};

export default DragDropPage;
