import React, { useState, useContext, useRef } from 'react';
import VariableContext from '../context/variableContext'; // Adjust the import path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import config from '../config'; // Adjust the import path as necessary
import '../styles/ManageCrossBreaks.css'; // Make sure the path matches your file structure
import axios from 'axios';
import LoadingIndicator from './LoadingIndicator';

const ManageCrossBreaks = () => {
    const { dataGroup, projectId, banners, setBanners } = useContext(VariableContext);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedBannerIndex, setSelectedBannerIndex] = useState(null);
    const [uniqueCode, setUniqueCode] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate hook
    const pasteRef = useRef(null); // ref for the paste input
    const [isLoading, setIsLoading] = useState(false);

    const removeRowFromBanner = (bannerIndex, rowIndex) => {
        const updatedBanners = banners.map((banner, index) => {
            if (index === bannerIndex) {
                return {
                    ...banner,
                    rows: banner.rows.filter((_, rIndex) => rIndex !== rowIndex)
                };
            }
            return banner;
        });
        setBanners(updatedBanners);
    };

    const selectBanner = (bannerIndex) => {
        setSelectedBannerIndex(bannerIndex);
    };

    const renderTabs = () => {
        return banners.map((banner, index) => (
            <button key={index} onClick={() => selectBanner(index)}>
                {banner.name || `Banner ${index + 1}`}
            </button>
        ));
    };

    const handleSelectionChange = (event) => {
        const itemName = event.target.value;
        const itemProperties = dataGroup[itemName];

        let newRows = [];

        if (itemProperties.type === "SingleResponse") {
            const variableName = itemName;
            const valueLabels = itemProperties.valueLabels;
            newRows = Object.keys(valueLabels).map(key => ({
                headerText: variableName,
                text: valueLabels[key],
                logic: `${variableName}=${key}`
            }));

            if (itemProperties.Nets) {
                itemProperties.Nets.forEach(net => {
                    const netIndices = net.indices.join(',');
                    newRows.push({
                        headerText: variableName,
                        text: `NET - ${net.label}`,
                        logic: `${variableName} in (${netIndices})`
                    });
                });
            }
        } else if (itemProperties.type === "Multiple2DGrid") {
            const variableName = itemName;
            const subVariables = itemProperties.Rows;
            const subVarNames = itemProperties.varName;
            newRows = subVariables.map((subVar, index) => ({
                headerText: variableName,
                text: subVar.title,
                logic: `${subVarNames.filter(x => x.row === subVar.index)[0].Name}=1`
            }));

            if (itemProperties.Nets) {
                itemProperties.Nets.forEach(net => {
                    const netVar = subVarNames.filter(x => net.indices.find(y => y === x.row)).map(x => x.Name + '=1');
                    const netIndices = netVar.join(' or ');
                    newRows.push({
                        headerText: variableName,
                        text: `NET - ${net.label}`,
                        logic: `${netIndices}`
                    });
                });
            }
        }

        if (newRows.length > 0) {
            if (selectedBannerIndex !== null) {
                const updatedBanners = banners.map((banner, index) => {
                    if (index === selectedBannerIndex) {
                        return {
                            ...banner,
                            rows: [...banner.rows, ...newRows]
                        };
                    }
                    return banner;
                });
                setBanners(updatedBanners);
            } else {
                const newBanner = {
                    name: `Banner ${banners.length + 1}`,
                    rows: newRows
                };
                setBanners([...banners, newBanner]);
            }
        }
        setSelectedItem({ [itemName]: itemProperties });
    };

    const addBanner = () => {
        const newIndex = banners.length + 1;
        const newBanner = {
            name: `Banner ${newIndex}`,
            rows: []
        };
        setBanners([...banners, newBanner]);
    };

    const handleBannerNameChange = (bannerIndex, newName) => {
        const updatedBanners = banners.map((banner, index) => {
            if (index === bannerIndex) {
                return { ...banner, name: newName };
            }
            return banner;
        });
        setBanners(updatedBanners);
    };

    const addRowToBanner = (bannerIndex) => {
        const newRow = { headerText: '', text: '', logic: '' };
        const updatedBanners = banners.map((banner, index) => {
            if (index === bannerIndex) {
                return {
                    ...banner,
                    rows: [...banner.rows, newRow]
                };
            }
            return banner;
        });
        setBanners(updatedBanners);
    };

    const handleRowChange = (bannerIndex, rowIndex, key, value) => {
        const updatedBanners = banners.map((banner, bIndex) => {
            if (bIndex === bannerIndex) {
                const updatedRows = banner.rows.map((row, rIndex) => {
                    if (rIndex === rowIndex) {
                        return { ...row, [key]: value };
                    }
                    return row;
                });
                return { ...banner, rows: updatedRows };
            }
            return banner;
        });
        setBanners(updatedBanners);
    };

    const getAlphabeticIdentifier = (index) => {
        let identifier = '';
        while (index >= 0) {
            identifier = String.fromCharCode((index % 26) + 65) + identifier;
            index = Math.floor(index / 26) - 1;
        }
        return identifier;
    };

    const renderSelectedBanner = () => {
        if (selectedBannerIndex !== null) {
            const selectedBanner = banners[selectedBannerIndex];
            return (
                <div key={selectedBannerIndex}>
                    <input
                        type="text"
                        value={selectedBanner.name}
                        onChange={(e) => handleBannerNameChange(selectedBannerIndex, e.target.value)}
                        placeholder="Enter Banner Name"
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Header Text</th>
                                <th>Text</th>
                                <th>Logic</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedBanner.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>
                                        <input
                                            type="text"
                                            value={row.headerText}
                                            onChange={(e) => handleRowChange(selectedBannerIndex, rowIndex, 'headerText', e.target.value)}
                                            ref={pasteRef}
                                            onPaste={handlePaste}
                                        />
                                    </td>
                                    <td>
                                    <input
                                        type="text"
                                        value={`${row.text} [${getAlphabeticIdentifier(rowIndex)}]`} // Adding alphabetic identifier
                                        onChange={(e) => handleRowChange(selectedBannerIndex, rowIndex, 'text', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={row.logic}
                                            onChange={(e) => handleRowChange(selectedBannerIndex, rowIndex, 'logic', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => removeRowFromBanner(selectedBannerIndex, rowIndex)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={() => addRowToBanner(selectedBannerIndex)}>
                        <FontAwesomeIcon icon={faPlusCircle} /> Add Banner Point
                    </button>
                </div>
            );
        }
        return null; // Or you could return a placeholder if no banner is selected
    };

    const saveBannersToBackend = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await axios.post(config.API_BASE_URL + '/savebanner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                banner: JSON.stringify(banners),
                project_id: projectId
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Banners saved, unique code:', data.code);
                setIsLoading(false); // Stop loading on success
                // You can now store this code in the state or display it to the user
            } else {
                console.error('Failed to save banners:', data);
                setIsLoading(false); // Stop loading on failure
            }
        } catch (error) {
            console.error('Error saving banners:', error);
            setIsLoading(false); // Stop loading on failure
        }
    };

    const goToUploadData = () => {
        navigate('/uploaddata'); // Replace '/upload-data' with the actual path to your Upload data page
    };

    const handlePaste = (e) => {
        if (selectedBannerIndex === null) return; // Make sure a banner is selected

        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');

        const rows = pastedData.split(/\r\n|\n|\r/).filter(row => row.length);
        const newRows = rows.map((row) => {
            const cells = row.split('\t'); // Assuming tab-separated values from Excel
            return {
                headerText: cells[0] || '',
                text: cells[1] || '',
                logic: cells[2] || '',
            };
        });

        setBanners(banners.map((banner, index) => {
            if (index === selectedBannerIndex) {
                return {
                    ...banner,
                    rows: [...banner.rows, ...newRows]
                };
            }
            return banner;
        }));
    };

    return (
        <div className="manage-cross-breaks">
            <div className="sidebar">
                <div className="navigation-buttons">
                    <button onClick={goToUploadData}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to Upload Data
                    </button>
                    {/* Other buttons */}
                </div>
                <h4>Manage Cross Breaks</h4>
                <select multiple size="10" onChange={handleSelectionChange} className="listbox">
                    {dataGroup && Object.keys(dataGroup).map((itemName) => ( // Check if dataGroup is defined
                        <option key={itemName} value={itemName}>
                            {itemName}
                        </option>
                    ))}
                </select>
            </div>
            <div className="workspace">
                {isLoading ? (
                    <LoadingIndicator message="Saving..." />
                ) : (
                    <>
                        <button onClick={addBanner}>
                            <FontAwesomeIcon icon={faPlus} /> Add Banner
                        </button>
                        <div className="tabs">
                            {renderTabs()}
                        </div>
                        <div className="selected-banner">
                            {renderSelectedBanner()}
                        </div>
                        <button onClick={saveBannersToBackend}>Save Banners</button>
                        {uniqueCode && <p>Unique Code: {uniqueCode}</p>}
                    </>
                )}
            </div>
        </div>
    );
};

export default ManageCrossBreaks;
