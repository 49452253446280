import background from '../images/background.png';

export const container = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden'
};

export const formContainer = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', 
    padding: '20px',
    borderRadius: '10px',
    margin: 0,
    width: '300px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
};

export const logo = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%',
    marginBottom: '10px'
};

export const input = {
    width: '100%',
    padding: '8px',
    margin: '8px 0',
    borderRadius: '5px',
    border: '1px solid #ccc'
};

export const button = {
    width: '100%',
    padding: '8px',
    margin: '8px 0',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'bold'
};

export const registerButton = {
    width: '100%',
    padding: '8px',
    margin: '8px 0',
    borderRadius: '5px',
    border: '2px solid #007BFF',
    backgroundColor: 'transparent',
    color: '#007BFF',
    cursor: 'pointer',
    fontWeight: 'bold'
};
export const socialButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    margin: '10px 0',
  };
  
  export const googleButtonStyle = {
    ...socialButtonStyle,
    backgroundColor: '#db4437',
    color: 'white',
  };
  
  export  const facebookButtonStyle = {
    ...socialButtonStyle,
    backgroundColor: '#4267B2',
    color: 'white',
  };