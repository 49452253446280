import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(() => {
        // Retrieve user data from local storage (if available)
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const { instance, accounts } = useMsal();

    // Update the isAuthenticated state on component mount
    useEffect(() => {
        // Check if the user is already authenticated
        if (user) {
            setIsAuthenticated(true);
        } else if (accounts.length > 0) {
            // If the user is authenticated with MSAL, set the user
            const account = accounts[0];
            const userData = {
                username: account.username,
                email: account.username // or other attributes from MSAL account
            };
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData)); // Store user data in local storage
            setIsAuthenticated(true);
        }
    }, [user, accounts]);

    const contextValue = {
        isAuthenticated,
        user,
        login: (userData) => {
            setIsAuthenticated(true);
            setUser(userData);
            localStorage.setItem('user', JSON.stringify(userData)); // Store user data in local storage
        },
        logout: () => {
            setIsAuthenticated(false);
            setUser(null);
            localStorage.removeItem('user'); // Clear user data from local storage
            instance.logoutRedirect(); // MSAL logout if needed
        }
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}
