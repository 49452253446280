import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import logoImage from '../images/download.jpg';
import { useAuth } from '../Providers/AuthContext' ; // Adjust the import path as necessary
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import config from '../config'; // Adjust the import path as necessary
import { useMsal } from "@azure/msal-react"; // Import useMsal hook for authentication
import { loginRequest } from "../msalConfig"; // Import loginRequest from your msalConfig

import { container, formContainer, registerButton,button,input,logo,facebookButtonStyle,googleButtonStyle,socialButtonStyle } from '../styles/initialStyles';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();
    const { instance } = useMsal(); // Get the MSAL instance using the useMsal hook

    const responseGoogle = (response) => {
        console.log(response);
        // Handle Google login success
    }

    const responseFacebook = (response) => {
        console.log(response);
        // Handle Facebook login success
    }
 const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.API_BASE_URL}/login`, {
                email,
                password
            });
            login(response.data); // Pass user data to login function
            navigate('/home');
            // Handle login success here (e.g., redirect, store token)
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Login failed:', error.response.data);
            } else {
                console.error('Login failed:', error.message);
            }
            // Handle login failure here
        }
    };
    // Office 365 Login
    // Office 365 Login
    const handleOffice365Login = async () => {
        try {
            const loginResponse = await instance.loginPopup(loginRequest);
console.log("Success")
            if (loginResponse) {
                const response = await fetch(`${config.API_BASE_URL}/loginOffice`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: loginResponse.account.username })
                });

                if (response.ok) {
                    const data = await response.json();
                    login(data); // Pass user data to login function
                    navigate('/home');
                } else {
                    console.error('Failed to fetch user data');
                }
            }
        } catch (error) {
            if (error.errorCode === 'hash_empty_error') {
                console.error('Login failed: Hash is empty. Please check your redirect URI configuration.');
            } else {
                console.error("Login failed:", error);
            }
        }
    };
     return (
        <div style={container}>
            <div style={formContainer}>
                <img src={logoImage} alt="Excavate Research" style={logo} />
                {/* <h2>Login</h2> */}
                {/* <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={input} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required style={input} />
                    </div>
                    <button type="submit" style={button}>Login</button>
                </form>
                <button onClick={() => navigate("/register")} style={registerButton}>Go to Registration</button>
                <GoogleLogin
                    clientId="288932788379-vgdt9cdeourj6emnli8tram6lv15ask4.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    style={googleButtonStyle}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} style={googleButtonStyle}>
                          <img src="/path/to/google-icon.png" alt="Google" />
                          Login with Google
                        </button>
                    )}
                />
                <FacebookLogin
                    appId="880053960084443"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="my-facebook-button-class"
                    icon="fa-facebook"
                    textButton="Login with Facebook"
                    buttonStyle={facebookButtonStyle}
                /> */}
                        <button onClick={handleOffice365Login} style={socialButtonStyle}>
          {/* <img src="/path/to/office365-icon.png" alt="Office 365" /> */}
          Login
        </button>

                </div>
        </div>
    );
}

export default LoginPage;
