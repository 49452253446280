import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile,faTable, faChartBar,faArrowLeft,faGear,faDownload,faGauge,faBuilding,faWeightScale,faPeopleRoof,faHouse,faLaptopFile,faChargingStation } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect,useContext } from 'react';
import { useAuth } from '../Providers/AuthContext'; // Adjust the import path as necessary
import * as XLSX from 'xlsx';
import { useLocation,useNavigate  } from 'react-router-dom';
import CrosstabTable from './CrosstabTable';
import FrequencyTableComponent from './FrequencyTableComponent';
import VariableContext from '../context/variableContext';
import MyChartComponent  from './MyChartComponent'
import config from '../config'; // Adjust the import path as necessary
import FrequencyDataTable from './FrequencyDataTable'; // Import the new component
import MultiResponse from './MultiResponse'; // Import the new component
import DataGrid from './DataGrid'; // Import the new component
import MultiDataGrid from './MultiDataGrid'; // Import the new component
import Grid3DData from './Grid3DData'; // Import the new component
import OpenProperties from './OpenProperties'; // Import the Modal component
import LoadingIndicator from './LoadingIndicator';

function UploadData() {
  const navigate = useNavigate(); // useNavigate hook for navigation
  const { user } = useAuth();
  
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const { setVariables,selectedClient, setSelectedClient,
    selectedProject, setSelectedProject,dataGroup,setdataGroup,projectId,setProjectId,
    retrieveBannersFromBackend,banners,setBanners,fetchWeights,savedWeightings } = useContext(VariableContext); // Use setVariables from context
  const [activeTab, setActiveTab] = useState(banners && banners.length>0? banners[0]?.name || '':'');
  const [contents, setContents] = useState([]);
    const [crosstabDisplayData, setCrosstabDisplayData] = useState(null); // State to store the crosstab data for display
    const [displayMode, setDisplayMode] = useState('table');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [dataList, setDataList] = useState([]);
    const [showFrequencyTable, setShowFrequencyTable] = useState(false); // New state to control table display
    const [refreshData, setRefreshData] = useState(false);    
    const [isLoading, setIsLoading] = useState(false);
    const [isCrossTab, setisCrossTab] = useState(false);
    const [projectClient, setProjectClient] = useState(''); // New state for project name
    const [clients, setClients] = useState([]);
    const [projectName, setProjectName] = useState(''); // New state for project name
    const location = useLocation();
    const [titles, setTitles] = useState([]);
    const [droppedTitles, setDroppedTitles] = useState([]); // New state for dropped titles
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [frequencyData, setFrequencyData] = useState(null);
    const [selectedVariable, setSelectedVariable] = useState(null);
    const [includeExtraData, setIncludeExtraData] = useState(false); // Checkbox state
    const [filenames, setFilenames] = useState([]); // Added filenames state
    const [selectedWeights, setSelectedWeights] = useState([]); // Added filenames state
    const [selectedBanner, setselectedBanner] = useState([]); // Added filenames state
    const weightingNames = savedWeightings ? Object.keys(savedWeightings) : [];
    const [exportType, setExportType] = useState('both');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clientsProjects, setClientsProjects] = useState([]);
    
    const [projects, setProjects] = useState([]);
    
    
    
// Dropdown component for selecting weightings
useEffect(() => {
  // Check if there are any banners
  if (banners && banners.length > 0) {
    setselectedBanner(banners[0]); // Set the first banner as selected
    setActiveTab(banners[0].name); // Also set the active tab to the first banner's name
  }
}, [banners]); // Depend on banners to re-run this effect
useEffect(() => {
  // Check if there are any saved weightings
  if (weightingNames.length > 0 && selectedWeights.length==0) {
    setSelectedWeights(weightingNames[0]); // Set the first weighting as selected
  }
}, [savedWeightings]); // Depend on savedWeightings to re-run this effect

const WeightingDropdown = ({ weightings, onSelectWeighting }) => {
  return (
    <select onChange={(e) => setSelectedWeights(e.target.value)} value={selectedWeights} style={{ marginLeft: '10px' }}>
      <option value="" >Select Saved Weighting</option>
		    		                {Object.keys(savedWeightings).map((name, index) => (
		    		                    <option key={index} value={name}>{name}</option>
		    		                ))}
    </select>
  );
};
    const handleTabChange = (tabName) => {
    
           
  setActiveTab(tabName.name);
  setselectedBanner(tabName);
    };

    useEffect(() => {
      
      if (projectId) {
        
          retrieveBannersFromBackend(projectId);
          fetchWeights();
      }
  }, []); // The empty array ensures this effect runs only on mount

    // Function to navigate to the Dashboard page
const goToDashboard = () => {
  // Optionally you could pass state like this if you're using React Router v5:
  // history.push('/dashboard', { data: /* your data to pass */ });

  // If using React Router v6, consider using useNavigate hook instead.
  navigate('/dashboard');
};
    const [displaySettings, setDisplaySettings] = useState({
      showFrequency: true,
      showPercent: true,
    });
    useEffect(() => {
      if (location.state && location.state.project) {
          setProjectName(location.state.project.project_name);
          // If there are files data in the location state, use that
          if (location.state.files) {
              //setDataList(location.state.files[0].qgroup_json);
              setdataGroup(location.state.files[0].qgroup_json);
              setFilenames(location.state.files.map((file) => file.filename)); // Update filenames state
   
          }
      }
  }, [location]);
  const handleAllTitlesClick = async () => {
    setIsLoading(true);
    setMessage('Processing all variables...');

    const titles = Object.keys(dataGroup);
    //const titles = Object.keys(dataList);
    const allFrequencyData = {};

    for (const title of titles) {
      //if(dataList[title]?.type && dataList[title].type!='Numeric' && dataList[title].type!='String')
        if(dataGroup[title]?.type && dataGroup[title].type!='Numeric' && dataGroup[title].type!='String')
        {
      const frequencyData = await handleTitleClick(title, false);
        if (frequencyData) {
            //dataList[title].frequencyData = frequencyData;
        }
      }
    }

    // Send all dataList to the backend for saving
    await saveAllDataListToBackend(dataGroup);
    //await saveAllDataListToBackend(dataList);

    setIsLoading(false);
    setMessage('All variables processed and data saved successfully.');
};


const getProjectList = async (client) => {
  try {
      const response = await fetch(`${config.API_BASE_URL}/projects`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ clientId: client})
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setProjects(data);
  
  } catch (error) {
      setMessage('An error occurred while fetching ProjectList.');
  }
};



const saveAllDataListToBackend = async (dataGroup) => {
  try {
      const response = await fetch(`${config.API_BASE_URL}/save_all_data_list`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ projectId: projectId, dataList: dataGroup })
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMessage('All dataList saved successfully.');
  } catch (error) {
      setMessage('An error occurred while saving dataList.');
  }
};
  const handleFilenameClick = (filename) => {
    const selectedFileData = location.state.files.find((file) => file.filename === filename);
    if (selectedFileData) {
      setdataGroup(selectedFileData.qgroup_json);
      //setDataList(selectedFileData.qgroup_json);
    }
  };
    useEffect(() => {
      if (location.state && location.state.project) {
          setProjectName(location.state.project.project_name);
          
      }
  }, [location]);
  useEffect(() => {
    // Load data from context (which should pull from local storage)
    const data = dataGroup; // Assuming dataGroup comes from context
    if (data) {
        // Set your component state based on the loaded data
        setDataList(data);
    }
}, [dataGroup]); // dataGroup is from VariableContext

  const handleDisplayModeChange = (mode) => {
    setDisplayMode(mode);
};
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleDisplaySettingsChange = (e) => {
    setDisplaySettings({
      ...displaySettings,
      [e.target.name]: e.target.checked,
    });
  };
  
  const handleTitleClick = async (title) => {
    setSelectedTitle(title);
    setisCrossTab(false);
    setIsLoading(true);
    //setFrequencyData(qgroupEntry); 
    const varNames = dataGroup[title]?.varName;
    //const varNames = dataList[title]?.varName;
    if (!varNames || varNames.length === 0) {
      setMessage('Variable names not found for the selected title.');
      return;
    }

    //setSelectedVariable(dataList[title]);
    setSelectedVariable(dataGroup[title]);

    // Check if the data already exists in the cache and refresh is not required
     const varData = dataGroup[title];
     //const varData = dataList[title];
// Use a combined key for caching purposes
// Check if data for this specific banner and title already exists in the cache and if refresh is not needed
if (varData.frequencyData && varData.frequencyData[selectedBanner?.name] && !refreshData) {
  
  setFrequencyData({...varData,freq_table:varData.frequencyData[selectedBanner?.name]}); // Assuming you want to populate the frequencyData state
  setShowFrequencyTable(true);
  setMessage('Frequency data loaded from cache.');
  setIsLoading(false);
  return; // Return since we do not need to fetch new data
}
  try {
      const payload = {
        varNames: dataGroup[title],
        //varNames: dataList[title],
        key:title,
        selectedWeights: selectedWeights, // Include selectedWeights in the payload
        selectedBanner: selectedBanner,    // Include selectedBanner in the payload
        projectId: projectId,
        refreshData:refreshData
      };
      const response = await fetch(`${config.API_BASE_URL}/frequency`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        setIsLoading(false);
      }
      const data = await response.json(); 
      //setFrequencyData(data.frequency_data); // Store frequency data in state
      setFrequencyData(data); // Store frequency data in state
      // Update the dataList with the fetched data for future reference, specifically under the selected banner
    //varData.frequencyData = varData.frequencyData || {}; // Ensure the frequencyData object exists
    //varData.frequencyData[selectedBanner?.name] = data.freq_table; // Store fetched data for this banner

      setShowFrequencyTable(true);
      setMessage('Frequency data retrieved successfully.');
      setIsLoading(false);

    } catch (error) {
      setMessage('An error occurred while fetching frequency data.');
      setIsLoading(false);

    } finally {
      setIsLoading(false);

    }
  };
  // Adjusted to render list items based on qgroup data
  const renderQGroupTitles = () => {
    
    if(dataGroup)
    {
    //return Object.entries(dataList).map(([title, qgroupEntry], index) => (
    return Object.entries(dataGroup).map(([title, qgroupEntry], index) => (
      <li key={title}
          
          onDragStart={(e) => handleDragStart(e, title)}
          style={{ padding: '5px 10px', cursor: 'pointer', backgroundColor: selectedTitle === title ? '#D3D3D3' : 'transparent' }}
          onClick={() => handleTitleClick(title)}>
        {qgroupEntry.title || title} {/* Show qgroup entry title or the key as a fallback */}
      </li>
    ));
  }
  };

  
  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file before uploading.');
      return;
    }
    if (!selectedProject) {
        setMessage('Please Select a Project.');
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', JSON.stringify(user)); // Add user details
    formData.append('project', JSON.stringify(selectedProject)); // Add project name
    formData.append('client', JSON.stringify(selectedClient)); // Add project name
    formData.append('includeExtraData', includeExtraData); // Send checkbox state
    setIsLoading(true);

    try {
      const response = await fetch(`${config.API_BASE_URL}/upload`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        setIsLoading(false);

      }
      const data = await response.json();

      const sortedKeys =Object.keys(data.qgroup).sort((a, b) => data.qgroup[a].order - data.qgroup[b].order);
      const sortedGroup = sortedKeys.reduce((acc, key) => {
        acc[key] = data.qgroup[key];
        return acc;
      }, {});
      const extractedTitles = Object.keys(sortedGroup);
      
      setProjectId(data.project_id);
      setTitles(extractedTitles);
      setVariables(extractedTitles);
      setDataList(sortedGroup);
      setdataGroup(sortedGroup);
      setMessage(data.message);
      fetchContents();
    } catch (error) {
      setMessage('An error occurred while uploading the file.');
      setIsLoading(false);

    } finally {
      setIsLoading(false);
    }
  };
 
  const toggleDisplayMode = () => {
    setDisplayMode(displayMode === 'chart' ? 'table' : 'chart');
  };
  // Function to render the frequency table for a single variable
  const handleDragStart = (e, title) => {
    e.dataTransfer.setData("title", title);
  };
  const handleDrop = (e, droppableId) => {
    
    e.preventDefault();
    const title = e.dataTransfer.getData("title");

    if (droppableId === "droppedTitles") {
      setDroppedTitles(prevTitles => [...prevTitles, title]);
    } else {
      // Handle other droppable areas if necessary
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;
    
    if (!destination) return; // Dropped outside the list
    if (destination.droppableId === source.droppableId && destination.index === source.index) return; // Dropped back to the same place
  
    const sourceTitle = titles[source.index];
    let targetTitle = null;
  
    // If the destination is within the bounds of the droppedTitles list, get the target title
    if (destination.droppableId === "droppedTitles" && destination.index < droppedTitles.length) {
      targetTitle = droppedTitles[destination.index];
    }
  
    // Update droppedTitles state
    setDroppedTitles((prevDroppedTitles) => {
      
      const newDroppedTitles = Array.from(prevDroppedTitles);
      // Remove the title from its original position if it was already in droppedTitles
      if (source.droppableId === "droppedTitles") {
        newDroppedTitles.splice(source.index, 1);
      }
      // Insert the title at its new position
      newDroppedTitles.splice(destination.index, 0, sourceTitle);
      return newDroppedTitles;
    });
  
    // Fetch cross-tabulation data if both titles are valid
   
  };
  const handleCrosstab = async () => {
    // Ensure that there is a selected title and at least one dropped title for cross-tabulation
    setisCrossTab(true);
    if (!selectedTitle || droppedTitles.length === 0) {
      setMessage('Please select a title and at least one dropped title for cross-tabulation.');
      return;
    }
  
    // The backend expects an array called 'varNames' with exactly two elements for cross-tabulation
    const payload = {
      //varNames: [selectedTitle, dataList[droppedTitles[0]]] // Take only the first dropped title for now
      varNames: [selectedTitle, dataGroup[droppedTitles[0]]] // Take only the first dropped title for now
    };
  
    setIsLoading(true);
    
    try {
      
      const response = await fetch(config.API_BASE_URL+'/crosstab', { // replace with your server address if different
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setCrosstabDisplayData(data); // Update the state with the crosstab data
      
      setMessage('Cross-tabulation data retrieved successfully.');
    } catch (error) {
      setMessage(`An error occurred while fetching cross-tabulation data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownloadExcel = () => {
    // Create a new workbook
    
    let dataArray = [];

    // Loop over each key in the dataList object
    //Object.keys(dataList).forEach(key => {
    Object.keys(dataGroup).forEach(key => {
      let rows = dataGroup[key].Rows;
      let cols = dataGroup[key].Cols;
      let maxRowCount = rows?.length;
      let maxColCount = cols?.length;
      let maxCount = Math.max(maxRowCount, maxColCount);
      
      for (let i = 0; i < maxCount; i++) {
        let rowObject = {};
  
        // Add name and Qtitle only for the first row of each key
        if (i === 0) {
          rowObject.name = key;
          rowObject.Qtitle = dataGroup[key].Qtitle;
          rowObject.Type = dataGroup[key].type;
        }
  
        // Add row details if they exist for this index
        if (i < maxRowCount) {
          rowObject[`indexRows`] = rows[i].index;
          rowObject[`Rows`] = rows[i].title;
        }
  
        // Add col details if they exist for this index
        if (i < maxColCount) {
          rowObject[`indexCols`] = cols[i].index;
          rowObject[`Cols`] = cols[i].title;
        }
  
        // Push each row object to the dataArray
        dataArray.push(rowObject);
      }
    });
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Convert dataArray to worksheet
    const ws = XLSX.utils.json_to_sheet(dataArray);
  
    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Data");
  
    // Write workbook and trigger download
    XLSX.writeFile(wb, "DataList.xlsx");
  };
  const downloadTxtFile = () => {


    fetch(`${config.API_BASE_URL}/generate-file`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ projectId, dataList,banners,'project': JSON.stringify(selectedProject), 'client': JSON.stringify(selectedClient) })
    })
    .then(response => response.json())
    .then(data => {
      fetchContents();
        console.log('Success:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};
  
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'flex-end', // Aligns child div to the right
      alignItems: 'center', // Centers items vertically
      marginBottom: '20px',
    },
    card: {
      
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      margin: '10px -100px',
    },
    button: {
      padding: '10px 15px',
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: 'background-color 0.3s',
      margin: '5px',
    },
    buttonUpload: {
      backgroundColor: '#5cb85c',
      color: 'white',
    },
    buttonDownload: {
      backgroundColor: '#f0ad4e',
      color: 'white',
    },
    buttonCrossTab: {
      backgroundColor: '#0275d8',
      color: 'white',
    },
    dropdownContainer: {
      display: 'flex',
      justifyContent: 'flex-end', // This will push the child elements to the opposite ends
      alignItems: 'center', // This will vertically center align the child elements
      paddingRight: '20px', // Adjust the padding to ensure it's within the right edge
    },
    weightingDropdown: {
      width: '150px', // Adjust the width as needed to make the dropdown narrower
    },
    // ... other styles
  };
  const handleRemoveTitle = (titleToRemove) => {
    setDroppedTitles(droppedTitles.filter(title => title !== titleToRemove));
  };
  const goToVariableLogicBuilder = () => {
    navigate('/variablelogicbuilder');
  };
  const navigateToWeighting = () => {
    navigate('/weighting');
};
const goToManageCrossBreaks = () => {
  navigate('/manage-crossbreaks');
};
const goToTableSettings = () => {
  navigate('/tablesettings');
};
const handleDownloadTopline = async () => {
  //const projectId = ''; // Replace with your actual projectId or ensure it's in scope

  // ...rest of your variable declarations (like selectedWeights)

  if (typeof projectId === 'undefined') {
    console.error('projectId is undefined');
    return;
  }

  if (!config || !config.API_BASE_URL) {
    console.error('API base URL is undefined');
    return;
  }

  const payload = {
    weighting: selectedWeights,
    projectId: projectId,
    //data:dataList
    data:dataGroup
  };

  try {
    const response = await fetch(`${config.API_BASE_URL}/downloadresults`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response) {
      const errorText = response.status === 404 ? 'Resource not found' : 'Network response was not ok';
      throw new Error(errorText);
    }

    const data = await response.json();
    console.log('Success:', data);
    // navigate('/uploaddata', { state: { project: projectId, files: data } }); // Use this if navigation is required
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

const toggleModal = () => {
  
  setIsModalOpen(prev => !prev);
};

const closeModalAndUpdateButtons = () => {
  setIsModalOpen(false);
  handleTitleClick(selectedTitle);
};


const handleExportTypeChange = (event) => {
  setExportType(event.target.value);
};

const exportFrequencies = async (type = 'both') => {
  const endpoint = type === 'both' ? '/export_both_frequencies' : '/export_frequencies';
  const requestData = { projectId: projectId };
  if (type !== 'both') {
    requestData.type = type;
  }

  try {
    const response = await fetch(`${config.API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = type === 'both' ? `frequency_data_${projectId}.xlsx` : `frequency_data_${projectId}_${type}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url); // Clean up
    setMessage('Frequency data exported successfully.');
  } catch (error) {
    setMessage(`An error occurred while exporting ${type} data: ${error.message}`);
  }
};
useEffect(() => {
  
  const fetchClientsProjects = async () => {
      try {
        setIsLoading(true);
        
        const response = await fetch(`${config.API_BASE_URL}/clients`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setClients(data);
          setIsLoading(false);
      } catch (error) {
          console.error('Failed to fetch clients and projects:', error);
          setIsLoading(false);
      }
  };

  fetchClientsProjects();
}, []);

const handleClientChange = (e) => {
  const client=clients.filter(x=> x.id==e.target.value);
  if(client.length>0)
  {
    setSelectedClient(client[0]);
  
    setSelectedProject(''); // Reset project selection when client changes
    getProjectList(client[0]);
  }
  
};

const handleProjectChange = (e) => {
  const project=projects.filter(x=> x.id==e.target.value);
  if(project.length>0)
    {
      setSelectedProject(project[0]);
    }
};
const goToHomePage = () => {
  setProjectId(null);
  setdataGroup(null);
  setBanners(null);
  navigate('/home'); // Replace '/upload-data' with the actual path to your Upload data page
};
const fetchContents = async () => {
  try {
    const response = await fetch(`${config.API_BASE_URL}/getListFolder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'project': JSON.stringify(selectedProject), 'client': JSON.stringify(selectedClient)})
    });

    const result = await response.json();
    setContents(result.value); // Adjust according to your response structure
  } catch (error) {
    setError(error.message);
  }
};

const handleFileUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming we're only dealing with the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Parse the worksheet into JSON
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      // Append excel data to dataList
      const updatedDataList = { ...dataGroup };

      excelData.forEach((row) => {
        const key = row.name;

        if (!updatedDataList[key]) {
          updatedDataList[key] = {
            Qtitle: row.Qtitle || '',
            type: row.Type || '',
            Rows: [],
            Cols: [],
          };
        }

        if (row.indexRows !== undefined) {
          updatedDataList[key].Rows.push({
            index: row.indexRows,
            title: row.Rows,
          });
        }

        if (row.indexCols !== undefined) {
          updatedDataList[key].Cols.push({
            index: row.indexCols,
            title: row.Cols,
          });
        }
      });

      setdataGroup(updatedDataList);
    };

    reader.readAsArrayBuffer(file);
  }
};
return (
  <div style={{ margin: '0 auto', width: '80%', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <div style={styles.card}>
              <div>
              <button onClick={goToHomePage}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Home
                </button>
                  <button onClick={handleAllTitlesClick}>Process All Variables</button>
                  <button onClick={() => exportFrequencies(exportType)}>Export Tables</button>
                  {message &&   <p>{message}</p>}

      <button style={{ ...styles.button, ...styles.buttonDownload }} onClick={handleDownloadTopline} title='Check Cross Breaks'>
      <FontAwesomeIcon icon={faLaptopFile} />        
      </button>
      <button style={{ ...styles.button, ...styles.buttonDownload }} onClick={handleDownloadExcel} title='Download Excel'>
      <FontAwesomeIcon icon={faDownload} size="lg" />        
      </button>
      <button style={{ ...styles.button, ...styles.buttonDownload }} onClick={downloadTxtFile} title='Uncle Programming'><FontAwesomeIcon icon={faChargingStation} /></button>
      <button onClick={goToDashboard} style={{ /* your button styles */ }} title='Go to Dashboard'>
      <FontAwesomeIcon icon={faGauge} size="lg" />

      </button>
      {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} /> */}
      <button
      style={{ ...styles.button, ...styles.buttonCrossTab }} // Apply your button styling here
      onClick={goToVariableLogicBuilder}
    title='Go to Variable Logic Builder'
    >
      
      <FontAwesomeIcon icon={faWeightScale} size="lg" />
    </button>
    <button style={{ ...styles.button, ...styles.buttonCrossTab }} type="button" onClick={navigateToWeighting} title='Go to Weighting Page'><FontAwesomeIcon icon={faPeopleRoof} size="lg" /></button>
    <button style={{ ...styles.button, ...styles.buttonCrossTab }} onClick={goToManageCrossBreaks} title='Manage Cross Breaks'><FontAwesomeIcon icon={faBuilding} />
</button>
    <button style={{ ...styles.button, ...styles.buttonCrossTab }} onClick={goToTableSettings} title='Table Settings'><FontAwesomeIcon icon={faGear} size="lg" /></button>
    </div>
    {isLoading ? (
        <LoadingIndicator message="Uploading ..." />
    ):(<>
      <h5 style={{ flex: 1 }}>{projectName}</h5>
      {(!dataGroup || Object.entries(dataGroup).length==0) && (<>
      <div>
        <h4>Files:</h4>
        <ul>
          {filenames.map((filename, index) => (
            <li key={index} onClick={() => handleFilenameClick(filename)} style={{ cursor: 'pointer' }}>
              {filename}
            </li>
          ))}
        </ul>
      </div>
      <div>
      <label htmlFor="client-dropdown">Select Client:</label>
      
      {isLoading ? (
                      <LoadingIndicator message="Loading clients..." />
                    ) : (
                      <select id="client-dropdown" value={selectedClient.id} onChange={handleClientChange}>
                        <option value="">--Select a Client--</option>
                        {clients.map((client) => (
                          <option key={client.id} value={client.id}>
                            {client.name}
                          </option>
                        ))}
                      </select>
                    )}
      
      {selectedClient && (
        <div>
          <label htmlFor="project-dropdown">Select Project:</label>
          <select
            id="project-dropdown"
            value={selectedProject.id}
            onChange={handleProjectChange}
          >
            <option value="">--Select a Project--</option>
            {projects.length > 0 ? (
              projects.map(project => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))
            ) : (
              <option value="">No projects available.</option>
            )}
          </select>
        </div>
      )}
        </div>

      <input type="file" onChange={handleFileChange} style={{ marginRight: '10px' }} />
      <div style={{ marginBottom: '10px' }}>
        <label>
          <input
            type="checkbox"
            checked={includeExtraData}
            onChange={(e) => setIncludeExtraData(e.target.checked)}
          />
          Replace Data as well
        </label>
      </div>
      
      <button style={{ ...styles.button, ...styles.buttonUpload }} onClick={handleUpload}>
        Upload
      </button></>)}</>)}
         </div>
        
        
         <div className="folder-container">
      <h2>Project Files</h2>
      <ul>
        {contents.length > 0 ? (
          contents.map(item => (
            <li key={item.id} className="folder-item">
            {item.folder && (<FontAwesomeIcon icon={faFolder} />)}
            
            {item.folder ? (
              <span className="item-name">{item.name}</span>
            ) : (
              <a
                href={`${config.API_BASE_URL}/download/${item.id}`}
                download
                className="item-name"
              >
                {item.name}
              </a>
            )}
          </li>
          ))
        ) : (
          <li>No files added</li>
        )}
      </ul>
    </div>
      
      </div>
      
      
      <div style={{ display: 'flex' }}>
      {/* First List - Titles */}
        <div style={{ width: '30%', overflowY: 'auto', maxHeight: '625px', marginLeft:'-100px', paddingRight: '20px' }}>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
          {renderQGroupTitles()}
          </ul>
        </div>
  
        {/* Second List and Frequency Data Container */}
        <div style={{ width: '70%' }}>
        <div style={styles.dropdownContainer}>
        <label>
  <input
    type="checkbox"
    checked={refreshData}
    onChange={(e) => setRefreshData(e.target.checked)}
  />
  Always refresh data
</label>
  {banners && banners.map((banner, index) => (
            <button
              key={index}
              onClick={() => handleTabChange(banner)}
              style={{
                padding: '10px',
                margin: '0 5px',
                backgroundColor: activeTab === banner.name ? '#0275d8' : '#f0f0f0',
                color: activeTab === banner.name ? '#ffffff' : '#000000',
              }}
            >
              {banner.name || `Unnamed Tab ${index + 1}`}
            </button>
        ))}  
  {weightingNames.length > 0 && (
    <WeightingDropdown
      weightings={weightingNames}
      
      style={styles.weightingDropdown}
    />
  )}
</div>     


          {/* Frequency Data Section */}
{/*           
          {showFrequencyTable && <FrequencyDataTable frequencyData={frequencyData} />} */}
    {isLoading ? (
        <LoadingIndicator message="Processing ..." />
    ):(<>
          {selectedTitle && (
            <div style={{ overflowX: 'auto' }}>
              <OpenProperties
                                    isOpen={isModalOpen}
                                    onClose={closeModalAndUpdateButtons}
                                    setdataGroup={setdataGroup}
                                    dataGroup={dataGroup}
                                    selectedKey={selectedTitle}
                                    selectedVariable={selectedVariable}
                                    setSelectedVariable={setSelectedVariable}
                                    projectId={projectId}
                                />
              <button onClick={() => toggleModal()} title='Update Properties'><FontAwesomeIcon icon={faGear} size="lg" /></button>
              <select style={{width:180}} value={exportType} onChange={handleExportTypeChange}>
                  <option value="percent">Export Percentages</option>
                  <option value="frequency">Export Frequencies</option>
                  <option value="both">Export Both</option>
                  </select>
              
              <h4>Frequency Data:</h4>
              
              <div>
                {/* <button onClick={toggleDisplayMode} style={{ border: 'none', background: 'none' }}>
                  {displayMode === 'chart'
                    ? <FontAwesomeIcon icon={faTable} size="lg" />
                    : <FontAwesomeIcon icon={faChartBar} size="lg" />
                  }
                </button> */}
                {frequencyData && displayMode === 'chart' && Object.entries(frequencyData).map(([varName, freq], index) => (
                  <div key={index}>
                    <h3>{varName}</h3> {/* Display the variable name as a header */}
                    <MyChartComponent frequencyData={freq} /> {/* Pass the frequency data for this variable to the chart */}
                  </div>
                ))}
              </div>
              
              {displayMode === 'table' && (
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="showFrequency"
                      checked={displaySettings.showFrequency}
                      onChange={handleDisplaySettingsChange}
                    />
                    Show Frequency
                  </label>
  
                  <label>
                    <input
                      type="checkbox"
                      name="showPercent"
                      checked={displaySettings.showPercent}
                      onChange={handleDisplaySettingsChange}
                    />
                    Show Percent
                  </label>
                </div>
              )}
  
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
              {
  !isCrossTab && frequencyData && (frequencyData.type=="SingleResponse" || frequencyData.type=="Numeric") && displayMode === 'table' && 
   (
    <FrequencyTableComponent
    freq_table={frequencyData}
      displaySettings={displaySettings}
    />
  )
}
{
  !isCrossTab && frequencyData && frequencyData.type=="Multiple2DGrid" && displayMode === 'table' && 
   (
    <MultiResponse
    varProperties={frequencyData}
    displaySettings={displaySettings}
    />
  )
}
{
  !isCrossTab && frequencyData && (frequencyData.type=="Single2DGrid" || frequencyData.type=="Numeric2DGrid") && displayMode === 'table' && 
   (
    <DataGrid
    gridData={frequencyData}
    displaySettings={displaySettings}
    />
  )
}
{
  !isCrossTab && frequencyData && (frequencyData.type=="Multiple3DGrid" || frequencyData.type=="Single3DGrid") && displayMode === 'table' && 
   (
    <MultiDataGrid
    gridData={frequencyData}
    displaySettings={displaySettings}
    />
  )
}
{
  !isCrossTab && frequencyData && (frequencyData.type=="Numeric3DGrid") && displayMode === 'table' && 
   (
    <Grid3DData
    gridData={frequencyData}
    displaySettings={displaySettings}
    />
  )
}

                {isCrossTab && crosstabDisplayData && (
                  
        <CrosstabTable crosstab={crosstabDisplayData} />
      )}
              </div>
            </div>
          )}</>)}
        </div>
      </div>
      
</div>
  );
  
}

export default UploadData;