import React, { useState, useContext,useEffect} from 'react';
import { useAuth } from '../Providers/AuthContext'; // Update the import path as necessary
import config from '../config'; // Adjust the import path as necessary
import VariableContext from '../context/variableContext';
import chartImage from '../images/Charts.jpg'; // You can replace with your image
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const { setdataGroup, setProjectId, setBanners, setWeightedCounts, setWeightingStats,selectedClient, setSelectedClient,
        selectedProject, setSelectedProject } = useContext(VariableContext);
    const [isHovering, setIsHovering] = useState(false);

        // Clear context state when HomePage is loaded
        useEffect(() => {
            setdataGroup(null);
            setBanners([]);
            setWeightedCounts({});
            setWeightingStats({});
        }, [setdataGroup, setBanners, setWeightedCounts, setWeightingStats]);

    if (!user) {
        navigate('/login');
    }

    function handleSignOut() {
        logout();
        navigate('/login');
    }

    function handleUploadData() {
        navigate('/uploaddata');
    }

    function handleProjectClick(project) {
        fetch(`${config.API_BASE_URL}/project/${project.id}/files`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.length > 0 && data[0].qgroup_json) {
                    setProjectId(project.id);
                    setdataGroup(data[0].qgroup_json);
                    setSelectedClient(data[0].client);
                    setSelectedProject(data[0].project);
                }
                navigate('/uploaddata', { state: { project, files: data } });
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }

    
    const renderProjects = () => {
        if (user && user.user.projects && user.user.projects.length > 0) {
            return user.user.projects.map((project, index) => (
                <li key={index} 
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => handleProjectClick(project)}>
                    {project.project_name}
                </li>
            ));
        } else {
            return <li>No projects found</li>;
        }
    };

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    return (
        <div style={styles.container}>
            <div style={styles.banner}>
                <img src={chartImage} alt="Example Chart" style={styles.image} />
            </div>

            <div style={styles.content}>
                <div style={styles.leftColumn}>
                    <h1>Welcome to Dashboard Creator</h1>
                    <p>Our application assists you in creating interactive and informative dashboards.</p>
                    <h2>Interactive Dashboards</h2>
                    <p>Visualize your data with a variety of charts and tools, making data interpretation easier and more intuitive.</p>
                </div>

                <div style={styles.middleColumn}>
                    <h2>Data Processing</h2>
                    <p>Our advanced algorithms preprocess and clean your data, ensuring accuracy and reliability in your visualizations.</p>
                    <h2>Data Weighting</h2>
                    <p>Assign weights and importance to your data points, emphasizing key information in your dashboards.</p>
                    <h2>Automation</h2>
                    <p>Automate tasks and updates, keeping your dashboards current with minimal effort.</p>
                </div>

                <div style={styles.rightColumn}>
                    <button
                        style={{
                            ...styles.addButton,
                            fontSize: isHovering ? '1rem' : '2rem',
                            backgroundColor: isHovering ? '#0056b3' : '#007bff',
                            width: isHovering ? '130px' : '40px',
                            borderRadius: isHovering ? '30%' : '50%'
                        }}
                        onClick={handleUploadData}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {isHovering ? 'Add new project' : '+'}
                    </button>
                    <h2>Existing Projects:</h2>
                    <ul style={styles.projectList}>
                        {renderProjects()}
                    </ul>
                </div>
            </div>

            <div style={styles.buttonBar}>
                <button style={styles.enterButton} onClick={handleUploadData}>Enter</button>
                <button style={styles.signOutButton} onClick={handleSignOut}>Sign Out</button>
            </div>
        </div>
    );
}

const styles = {
    addButton: {
        color: 'white',
        padding: '10px',
        fontSize: '24px',
        lineHeight: '20px',
        transition: 'background-color 0.3s, width 0.3s',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '50%',
        height: '40px',
        textAlign: 'center',
        margin: '10px',
        display: 'inline-block',
        verticalAlign: 'top',
        whiteSpace: 'nowrap'
    },
    container: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'space-between'
    },
    banner: {
        width: '100%',
        marginBottom: '20px',
        height: '200px'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    buttonBar: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '20px'
    },
    enterButton: {
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        marginRight: '10px'
    },
    signOutButton: {
        backgroundColor: '#FF5733',
        color: 'white',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: '5px',
        marginLeft: '10px'
    },
    projectList: {
        listStyleType: 'disc',
        paddingLeft: '20px'
    },
    projects: {
        marginBottom: '20px'
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box'
    },
    leftColumn: {
        flex: 1,
        padding: '0 10px',
        boxSizing: 'border-box'
    },
    middleColumn: {
        flex: 1,
        padding: '0 10px',
        boxSizing: 'border-box'
    },
    rightColumn: {
        flex: 1,
        padding: '0 10px',
        boxSizing: 'border-box'
    },
    projectsColumn: {
        padding: '0 10px',
        boxSizing: 'border-box'
    }
};

export default HomePage;
