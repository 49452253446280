import React from 'react';
import '../styles/LoadingIndicator.css'; // Assume we're using a separate CSS file for styles

const LoadingIndicator = ({ message }) => {
    return (
        <div className="loading-indicator">
            <div className="loading-dots">
                <span>.</span><span>.</span><span>.</span>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default LoadingIndicator;
