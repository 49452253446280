import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom'; // Import useHistory
import logoImage from '../images/download.jpg';  // Assuming you're using the same logo
import background from '../images/bg2.png';  // Assuming you're using the same background
import { formContainer, button,input,logo } from '../styles/initialStyles';
import config from '../config'; // Adjust the import path as necessary

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate hook
    const styles = {
        container: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden'
        },
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.API_BASE_URL}/register`, {
                username,
                email,
                password
            });
            console.log(response.data);
            
            navigate('/login')
            // Handle registration success here
        } catch (error) {
            console.error('Registration failed:', error.message);
            // Handle registration failure here
        }
    };

    return (
        <div style={styles.container}>
            <div style={formContainer}>
                <img src={logoImage} alt="Company Logo" style={logo} />
                <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required style={input} />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={input} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required style={input} />
                    </div>
                    <button type="submit" style={button}>Register</button>
                </form>
            </div>
        </div>
    );
}

export default RegisterPage;
